<template>
  <div id="section_proind" class="section_proind">
    <Navigator />
    <div class="bloqueqp">
      <h1 class="title_pi">Projects </h1>
      <div class="bloquepro">
        <div class="col1pro">
          <hr />
          <h4 class="titpro tp1">{{datapro.title}}</h4>
          <h4 class="titpro tp2">{{datapro.category_name}}</h4>
        </div>
        <div class="col2pro">
          <section v-for="(d, i) in datapro.body" :key="i">
            <div class="bloquevideo" v-if="d.tipo == 'video'">
              <div class="video-f" v-html="d.video"></div>

              <!--<iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/csakpQQL6j4"
                    title="P758 Flex N Gate"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    ></iframe>-->
            </div>
            <section v-if="d.tipo == 'galeria'">
              <div class="bloquevideo bcentered">
                <img
                  :src="srcImg(d.galeria[selimg])"
                  alt=""
                  class="principal-img"
                />
                <!--<iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/csakpQQL6j4"
                    title="P758 Flex N Gate"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    ></iframe>-->
              </div>
              <agile :options="opcionesD" :class="{'atwoitems':d.galeria.length < 3}">
                <div v-for="(img, i) in d.galeria" :key="i" @click="selimg = i" class="img-sl">
                  <img :src="srcImg(img)" alt="img" />
                </div>
              </agile>
            </section>
          </section>
          <section v-for="(d, i) in datapro.body" :key="i">
            <div class="blodescrip" v-if="d.tipo == 'caja'">
              <div
                class="coldescrip"
                v-if="d.caja[0].tipo == 'texto'"
                v-html="d.caja[0].texto"
              >
                <!--<p>
                            For this project we had the opportunity to work with “Legacy
                            Industries” supplying manpower for their installation phase, as
                            well as the startup and commissioning phase providing robotics
                            and PLC support.
                        </p>
                        <p>
                            For this project our mechanical/electrical team planned a robust
                            plan of action for a limited timeframe between the install phase
                            and SOP.
                        </p>-->
              </div>
              <div
                class="coldescrip"
                v-if="d.caja[1].tipo == 'texto'"
                v-html="d.caja[1].texto"
              >
                <!--<p>
                        Our controls department had a challenging project due the
                        limited timeframe available and our robotics team did a great
                        job on cycle time reduction as well as a part development
                        support which help our clients to reach their milestones on the
                        planned dates.
                    </p>--->
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
  <script>
import Navigator from "../components/Navigator";
import Footer from "../components/Footer.vue";
import { url, endpointCodes } from "../global/index";
import { mapActions } from "vuex";
export default {
  name: "projectfng",
  components: {
    Navigator,
    Footer,
  },
  data() {
    return {
      selimg: 0,
      opcionesD: {
        infinite: true,
        autoplay: false,
        slidesToShow: 3,
        navButtons: true,
        dots: false,
      },
    };
  },
  async created() {
    let payload = {
      option: "project-url",
      url: this.$route.params.url,
    };
    await this.getInfoByUrlPrj(payload);
  },

  computed: {
    datapro() {
      return this.$store.getters["project/data"];
    },
  },

  methods: {
    ...mapActions("project", ["getInfoByUrlPrj"]),
    ...mapActions("project", ["setHistoryOptionPrj"]),
    ...mapActions("project", ["setAddedPrj"]),
    srcImg: function (img) {
      let src = `${url}/${endpointCodes.get}/project-img/${img}`;

      return src;
    },
  },
};
</script>
  
  
  <style>
.section_proind {
  background-image: url(../assets/fondosec.png);
  background-repeat: no-repeat;
  background-size: 57.813084vw;
  background-position: 42vw 29.5vw;
}
.bars {
  filter: invert(17%) sepia(97%) saturate(4851%) hue-rotate(241deg)
    brightness(93%) contrast(114%) !important;
}
h1.title_pi {
  text-align: center;
  margin: 0px auto;
  color: var(--color-2);
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 8.177vw;
  line-height: 10.28vw;
  padding-top: 60.981vw;
  margin-bottom: 27.205607vw;
}
img {
  width: 100%;
  height: auto;
}
.bloquepro {
  width: 87%;
  margin: auto;
  padding-bottom: 30.373831775700932vw;
}
.col1pro hr {
  margin: 0;
  background: #06c5ff;
  height: 2px;
  border: none;
  width: 10%;
  margin-bottom: 7.943925233644859vw;
}
h4.titpro {
  margin: 0;
  font-size: 3.7383177570093453vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
}
.tp1 {
  color: var(--color-2);
}
.tp2 {
  color: var(--color-1);
  margin-bottom: 7.710280373831775vw !important;
}/*
.col2pro img {
  margin-bottom: 11.682242990654206vw !important;
}*/
.blodescrip p {
  font-size: 3.2710280373831773vw;
  color: #676467;
  line-height: 4.995327102803738vw;
  margin: 0;
  margin-bottom: 5.841121495327103vw;
}
.bloquevideo {
  margin-bottom: 8vw;
  border: 2.5vw solid #262ffd;
}
.bloquevideo iframe {
  border: 2.5vw solid transparent;
  width: 93% !important;
  height: 87.68vw;
}
.img-sl img{ 
    cursor: pointer;
}
.img-sl img{
    width: 27.053vw;
    height: 27.053vw;
    object-fit: cover; 
}
.bcentered {
    display: flex;
    align-items: center;
    justify-content: center;
} 
@media (min-width: 1024px) {
  .atwoitems .agile__actions {
    display: none;
} 
  .section_proind {
    background-image: url(../assets/servicios/log.png);
    background-position: 54vw -10.5vw;
    background-size: 45.813084vw;
  }
  .bloqueqp {
    width: 90%;
    margin: auto;
  }
  h1.title_pi {
    text-align: left;
    margin: 0px auto;
    font-size: 3.6458333333333335vw;
    line-height: 4.611979166666667vw;
    padding-top: 13.291667vw;
    margin-bottom: 8.541666666666666vw;
  }
  .bloquepro {
    width: 100%;
    margin: auto;
    padding-bottom: 30.373831775700932vw;
    display: flex;
  }
  .col1pro {
    text-align: right;
    width: 30%;
  }
  .col2pro {
    width: 70%;
    margin-left: 4.635416666666667vw;
  }
  h4.titpro {
    margin: 0;
    font-size: 1.5625vw;
  }
  .tp2 {
    width: 80%;
    float: right;
  }
  .col2pro img {
    width: 46vw;
    height: 30vw;
    object-fit: cover;
    /*margin-bottom: 3.682243vw !important;*/
  }
  .blodescrip {
    display: flex;
  }
  .coldescrip {
    width: 45%;
  }
  .blodescrip p {
    font-size: 1.0416666666666665vw;
    color: #676467;
    line-height: 1.590625vw;
    margin: 0;
    margin-bottom: 1.841121vw;
    width: 88%;
  }
  .bloquevideo {
    width: 47.65625vw;
    height: 31.874999999999996vw;
    margin-bottom: 4vw;
    border: 0.625vw solid #262ffd;
  }
  .bloquevideo iframe {
    border: 0.625vw solid transparent;
    width: 97% !important;
    height: 30.6vw;
  }
  .img-sl {
    display: flex;
    justify-content: center;
}
  .img-sl img {
    width: 8.073vw!important;
    height: 8.073vw!important;
    object-fit: cover;
}
.section_proind .agile {
    width: 30vw;
    position: absolute;
    left: 5vw;
    margin-top: -12vw;
}
.section_proind .agile__nav-button {
    padding: 0;
    border: none;
    background: transparent;
    color: blue;
    font-size: 3vw;
}
.section_proind .agile__actions {
    width: 10vw;
    margin: auto;
}
}
</style>